<template>
  <div class="controls mb-2">
    <base-button
      id="back-button"
      :text="$t('utils.toCompanyList')"
      type="secondary"
      :icon="backIcon"
      full-width
      @click="handleBack()"
      @keyup.enter="handleBack()"
    />
    <base-button
      v-if="userStore.hasWriteRole"
      id="generate-report-button"
      :text="$t('maintenance.generateReport')"
      type="primary"
      :icon="generateIcon"
      full-width
      @click="openSidePanel = true"
      @keyup.enter="openSidePanel = true"
    />
  </div>
  <div class="mt-3">
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <div
      v-if="notificationMessage"
      id="notification"
      class="mb-2"
    >
      <aab-notification
        close-icon
        :close-icon-aria-label="$t('utils.closeAria')"
        :type="notificationType"
        :screenreader-type="$t(`utils.${notificationType}Aria`)"
        @aab-notification-close-icon-clicked="closeNotification"
      >
        <p>{{ notificationMessage }}</p>
      </aab-notification>
    </div>
    <div
      v-if="!loading"
      id="tableArea"
    >
      <h3>{{ $t('maintenance.reportsLabel') }}</h3>
      <base-table
        :fields="fields"
        :items="reports"
        :error="tableError"
        :empty-message="$t('maintenance.noReports', { company: companyName })"
        @icon-clicked="handleClick($event)"
      />
      <div id="pagination">
        <aab-pagination
          items-per-page="[25,50,75]"
          :selected-page-item="pageSize"
          :selected-page-number="pageNumber"
          :total-items="totalItems"
          @aab-pagination-updated="updateReports($event)"
        />
      </div>
    </div>
    <aab-sidepanel
      :is-open="openSidePanel"
      :side-panel-title="$t('maintenance.generateReport')"
      disable-background
      footer-preset="1"
      :footer-buttons="sidePanelFooterButtons"
      @side-panel-closed="closeModal"
      @side-panel-footer-action-click="handlePanelAction($event)"
    >
      <div class="px-3">
        <div v-if="generateReportLoading">
          <aab-spinner />
        </div>
        <div
          v-if="generateReportNotificationMessage"
          id="generateReportNotification"
          class="mb-2"
        >
          <aab-notification
            close-icon
            :close-icon-aria-label="$t('utils.closeAria')"
            :type="generateReportNotificationType"
            :screenreader-type="$t(`utils.${generateReportNotificationType}Aria`)"
            @aab-notification-close-icon-clicked="closeNotification"
          >
            <p>{{ generateReportNotificationMessage }}</p>
          </aab-notification>
        </div>
        <div v-if="!generateReportLoading">
          <base-typology>
            The report will be generated for the period filled in below.
          </base-typology>
          <form>
            <aab-datepicker
              with-range
              calendar-placement="bottom"
              locale="en"
              :left-label="$t('maintenance.startDate')"
              :right-label="$t('maintenance.endDate')"
              :message="dateValidationMessage"
              :message-type="(v$.startDateGenerateReport.$error || v$.endDateGenerateReport.$error)
                ? 'warning' : null"
              :from-input-state="v$.startDateGenerateReport.$error ? 'warning' : null"
              :to-input-state="v$.endDateGenerateReport.$error ? 'warning' : null"
              @aab-datepicker-date-from-changed="handleChangeStartDate($event)"
              @aab-datepicker-date-to-changed="handleChangeEndDate($event)"
            />
          </form>
        </div>
      </div>
    </aab-sidepanel>
  </div>
</template>

<script>
import {
  sy_arrow_chevron_left as backIcon,
  sy_arrow_download as downloadIcon,
  sy_form_document_add as generateIcon,
} from '@aab/sc-aab-icon-set';
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseTable from '@/components/base/BaseTable.vue';
import {
  downloadReport,
  generateReport,
  getCompany,
  getReports,
} from '@/services/maintenanceService';
import { g300 } from '@aab/sc-styles-colors';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/utils/customValidation';
import { formatDateFromUnix } from '@/utils/date';
import useUserStore from '@/stores/UserStore';

export default defineComponent({
  name: 'ReportList',
  components: { BaseTypology, BaseButton, BaseTable },
  emits: ['change-title'],
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      v$: useVuelidate(),
      backIcon,
      generateIcon,
      loading: false,
      generateReportLoading: false,
      pageNumber: 1,
      pageSize: 25,
      totalItems: null,
      reports: [],
      companyName: null,
      tableError: null,
      notificationMessage: null,
      notificationType: null,
      generateReportNotificationMessage: null,
      generateReportNotificationType: null,
      openSidePanel: null,
      startDateGenerateReport: null,
      endDateGenerateReport: null,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'startDate',
          label: 'Start date',
          sortable: true,
          sortKey: 'startDateNumber',
        },
        {
          key: 'endDate',
          label: 'End date',
          sortable: true,
          sortKey: 'endDateNumber',
        },
        {
          key: 'created',
          label: 'Creation date',
          sortable: true,
          sortKey: 'createdDateNumber',
        },
        {
          key: 'createdBy',
          label: 'Created by',
          sortable: true,
          sortKey: 'createdBy',
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          template: 'icon',
        },
      ];
    },
    dateValidationMessage() {
      if (this.v$.startDateGenerateReport.$error && this.v$.endDateGenerateReport.$error) {
        return this.$t('validationErrors.requiredPlural', { fields: `'${this.$t('maintenance.startDate')}' and '${this.$t('maintenance.endDate')}'` });
      }
      if (this.v$.startDateGenerateReport.$error) {
        return this.v$.$errors.filter((e) => e.$property === 'startDateGenerateReport')[0].$message;
      }
      if (this.v$.endDateGenerateReport.$error) {
        return this.v$.$errors.filter((e) => e.$property === 'endDateGenerateReport')[0].$message;
      }
      return null;
    },
    sidePanelFooterButtons() {
      return JSON.stringify([
        {
          text: this.$t('maintenance.generate'),
          icon: generateIcon,
          action: 'generateReport',
        },
        {
          text: this.$t('utils.cancel'),
          icon: backIcon,
          action: 'cancelGeneration',
        },
      ]);
    },
  },
  async created() {
    if (this.$route.params.companyId) {
      await this.getCompanyData();
      await this.getReports();
    } else {
      this.tableError = this.$t('maintenance.errors.getReports');
    }
    if (this.companyName) {
      this.$emit(
        'change-title',
        this.$t('maintenance.reportsTitle', { company: this.companyName }),
        this.$t('maintenance.reportsLabel'),
      );
    } else {
      this.$emit('change-title', this.$t('maintenance.reportsLabel'), null);
    }
  },
  methods: {
    handleBack() {
      this.$router.push('/maintenance/companies');
    },
    async getCompanyData() {
      this.notificationMessage = null;
      this.notificationType = null;
      try {
        const data = await getCompany(this.$route.params.companyId);
        if (data) {
          this.companyName = data.companyName;
        }
      } catch (e) {
        this.notificationType = 'warning';
        this.notificationMessage = `${this.$t('maintenance.errors.getCompany')} ${e.message}`;
      }
    },
    async getReports() {
      this.loading = true;
      this.tableError = null;
      try {
        const data = await getReports(
          this.$route.params.companyId,
          this.pageSize,
          this.pageNumber,
        );
        if (data) {
          this.reports = this.addActions(data.reports);
          this.totalItems = data.totalItems;
        }
      } catch (e) {
        this.tableError = `${this.$t('maintenance.errors.getReports')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
    addActions(reports) {
      return reports.map((report) => ({
        ...report,
        actions: [{
          size: '3',
          svg: downloadIcon,
          color: g300,
          action: 'download',
          data: report.reportId,
          label: 'Download report',
          role: 'button',
        }],
      }));
    },
    async updateReports(event) {
      this.pageSize = event.detail.value.itemsPerPage;
      this.pageNumber = event.detail.value.pageNumber;
      await this.getReports();
    },
    handleClick(event) {
      if (event.detail.name === 'download') {
        this.notificationMessage = null;
        this.notificationType = null;
        try {
          const response = downloadReport(this.$route.params.companyId, event.detail.data);
          if (response === 204) {
            this.notificationType = 'info';
            this.notificationMessage = this.$t('maintenance.reportEmpty');
          }
        } catch (e) {
          this.notificationType = 'warning';
          this.notificationMessage = `${this.$t('maintenance.errors.downloadReport')} ${e.message}`;
        }
      }
    },
    handleChangeStartDate(event) {
      this.startDateGenerateReport = formatDateFromUnix(event.detail.value);
      this.v$.startDateGenerateReport.$touch();
    },
    handleChangeEndDate(event) {
      this.endDateGenerateReport = formatDateFromUnix(event.detail.value);
    },
    handlePanelAction(event) {
      if (event.detail === 'cancelGeneration') {
        this.closeModal();
      } else if (event.detail === 'generateReport') {
        this.generateReport();
      }
    },
    async generateReport() {
      if (!await this.v$.$validate()) {
        return;
      }
      this.generateReportLoading = true;
      try {
        const response = await generateReport(
          this.$route.params.companyId,
          this.startDateGenerateReport,
          this.endDateGenerateReport,
        );
        await this.getReports();
        if (response === 204) {
          this.generateReportNotificationType = 'info';
          this.generateReportNotificationMessage = this.$t('maintenance.reportEmpty');
        } else if (response === 200) {
          this.closeModal();
        }
      } catch (e) {
        this.generateReportNotificationType = 'warning';
        this.generateReportNotificationMessage = `${this.$t('maintenance.errors.downloadReport')} ${e.message}`;
      } finally {
        this.generateReportLoading = false;
      }
    },
    closeModal() {
      this.openSidePanel = null;
      this.startDateGenerateReport = null;
      this.endDateGenerateReport = null;
      this.v$.$reset();
    },
    closeNotification() {
      this.notificationMessage = null;
      this.notificationType = null;
    },
  },
  validations() {
    return {
      startDateGenerateReport: { required: helpers.withParams({ field: 'maintenance.startDate' }, required) },
      endDateGenerateReport: { required: helpers.withParams({ field: 'maintenance.endDate' }, required) },
    };
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mt_3;
@include lib.px_3;

.controls {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: space-between;
}

</style>
