import { decodeJwt } from 'jose';

/**
 * Decodes the roles from JSON Web Token payload.
 * This does not validate the JWT Claims Set types or values.
 * This does not validate the JWS Signature.
 *
 * @param jwt jwt payload
 * @returns array of roles
 */
const getRolesJwt = (jwt) => {
  const claims = decodeJwt(jwt);
  return claims.auth.split(',');
};

// eslint-disable-next-line import/prefer-default-export
export { getRolesJwt };
