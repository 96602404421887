<template>
  <header class="header-wrapper bg-white">
    <div class="em-header">
      <div class="container-fluid em-page-grid overflow-show">
        <div class="row px-0 pt-1 pt-lg-2 mx-0">
          <div
            id="header"
            class="col"
          >
            <AabLogo></AabLogo>
          </div>
          <div class="col">
            <HeaderProfile />
          </div>
        </div>
      </div>
    </div>
    <NavBar />
  </header>
</template>

<script>
import { defineComponent } from 'vue';
import AabLogo from '@/components/header/AabLogo.vue';
import NavBar from '@/components/header/NavBar.vue';
import HeaderProfile from '@/components/header/HeaderProfile.vue';

export default defineComponent({
  name: 'TheHeader',
  components: {
    HeaderProfile,
    NavBar,
    AabLogo,
  },
});

</script>

<style lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.col;
@include lib.container_fluid;
@include lib.em_page_grid;
@include lib.mx_0;
@include lib.overflow_show;
@include lib.px-0;
@include lib.pt-1;
@include lib.pt-lg-0;
@include lib.pt-lg-2;
@include lib.row;

.bg-white {
  background-color: lib.$white;
}

header {
  .em-header {
    min-height: 72px;
  }

  .header-wrapper a:hover {
    color: lib.$g200;
    text-decoration: none;
  }

  .header-wrapper a:active {
    color: lib.$g500;
    text-decoration: none;
  }

  .em-brand {
    height: 44px;
    width: 32px;
    background-size: 176px 44px;
    background-repeat: no-repeat;
  }
}
</style>
