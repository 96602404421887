import LoginView from '@/views/LoginView.vue';
import navItems from '@/utils/reference/navItems';
import MaintenanceView from '@/views/MaintenanceView.vue';
import UsersView from '@/views/UsersView.vue';
import CompanyForm from '@/components/maintenance/CompanyForm.vue';
import CompanyList from '@/components/maintenance/CompanyList.vue';
import ManageAccounts from '@/components/maintenance/ManageAccounts.vue';
import ReportList from '@/components/maintenance/ReportList.vue';
import UserList from '@/components/user/UserList.vue';
import UserForm from '@/components/user/UserForm.vue';
import UserAccountView from '@/views/UserAccountView.vue';
import AccountDetails from '@/components/user/AccountDetails.vue';
import ChangePassword from '@/components/user/ChangePassword.vue';

export default [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'login' },
  },
  {
    path: navItems.maintenance.route,
    name: navItems.maintenance.routeName,
    component: MaintenanceView,
    redirect: '/maintenance/companies',
    meta: {
      requiresAuth: navItems.maintenance.requiresAuth,
      requiresRoles: navItems.maintenance.requiresRoles,
    },
    children: [
      {
        path: navItems.maintenance.companies.route,
        name: navItems.maintenance.companies.routeName,
        component: CompanyList,
      },
      {
        path: navItems.maintenance.addCompany.route,
        name: navItems.maintenance.addCompany.routeName,
        component: CompanyForm,
        props: { action: 'add' },
      },
      {
        path: navItems.maintenance.updateCompany.route,
        name: navItems.maintenance.updateCompany.routeName,
        component: CompanyForm,
        props: { action: 'update' },
      },
      {
        path: navItems.maintenance.accounts.route,
        name: navItems.maintenance.accounts.routeName,
        component: ManageAccounts,
      },
      {
        path: navItems.maintenance.reports.route,
        name: navItems.maintenance.reports.routeName,
        component: ReportList,
      },
    ],
  },
  {
    path: navItems.userManagement.route,
    name: navItems.userManagement.routeName,
    component: UsersView,
    redirect: '/users/overview',
    meta: {
      requiresAuth: navItems.userManagement.requiresAuth,
      requiresRoles: navItems.userManagement.requiresRoles,
    },
    children: [
      {
        path: navItems.userManagement.users.route,
        name: navItems.userManagement.users.routeName,
        component: UserList,
      },
      {
        path: navItems.userManagement.addUser.route,
        name: navItems.userManagement.addUser.routeName,
        component: UserForm,
      },
    ],
  },
  {
    path: navItems.account.route,
    name: navItems.account.routeName,
    component: UserAccountView,
    redirect: '/account/myAccount',
    meta: {
      requiresAuth: navItems.account.requiresAuth,
    },
    children: [
      {
        path: navItems.account.myAccount.route,
        name: navItems.account.myAccount.routeName,
        component: AccountDetails,
      },
      {
        path: navItems.account.changePassword.route,
        name: navItems.account.changePassword.routeName,
        component: ChangePassword,
      },
    ],
  },
  {
    path: '/activate-account',
    name: 'ActivateAccountView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'activate' },
  },
  {
    path: '/request-reset-password',
    name: 'RequestResetPasswordView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'forgotten' },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'reset' },
  },
  {
    // must be last as fallback
    path: '/:pathMatch(.*)*',
    redirect: '/login',
  },
];
