import axios from 'axios';
import createError from '@/utils/errors';
import { lockedStatus } from '@/utils/reference/globalConstants';
import * as changeCase from 'change-case';

function createLabel(value) {
  const label = {
    statusMessage: value,
    hasIcon: false,
    hasBackground: true,
    styleType: 'positive',
  };
  switch (value) {
    case 'Pending':
      label.styleType = 'warning';
      break;
    case 'Locked':
      label.styleType = 'negative';
      break;
    case 'Disabled':
      label.styleType = 'negative';
      break;
    default:
      break;
  }
  return label;
}

function createUserData(users) {
  const data = Object.keys(users.list)
    .map((key) => ({
      id: users.list[key].userId,
      email: users.list[key].email,
      name: `${users.list[key].firstName} ${users.list[key].lastName}`,
      username: changeCase.capitalCase(users.list[key].username),
      roles: users.list[key].roles.map((role) => changeCase.sentenceCase(role)).join(', '),
      accountStatus: createLabel(changeCase.sentenceCase(users.list[key].accountStatus)),
      lockedStatus: createLabel(lockedStatus[users.list[key].locked]),
    }));
  return {
    users: data,
    totalItems: users.totalElements,
  };
}

export async function getUsers(pageSize, pageNumber) {
  return axios.get(`v1/users?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    .then((resp) => createUserData(resp.data))
    .catch((error) => createError(error));
}

export async function getUser() {
  return axios.get('v1/me')
    .then((resp) => resp.data)
    .catch((error) => createError(error));
}

export async function addUser(user) {
  const body = {
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    roles: [user.role],
  };
  return axios.post('v1/users', body)
    .catch((error) => createError(error));
}

export async function deleteUser(userId) {
  return axios.delete(`v1/users/${userId}`)
    .catch((error) => createError(error));
}

export async function activateAccount(password, token) {
  const body = {
    newPassword: password,
    token,
  };
  return axios.post('v1/account/activate', body)
    .catch((error) => createError(error));
}

export async function updatePassword(currentPassword, newPassword) {
  const body = {
    currentPassword,
    newPassword,
  };
  return axios.put('v1/users/update-password', body)
    .catch((error) => createError(error));
}

export async function resetPasswordInit(username) {
  const body = {
    username,
  };
  return axios.post('v1/reset-password/init', body)
    .catch((error) => createError(error));
}

export async function resetPasswordFinish(password, token) {
  const body = {
    newPassword: password,
    token,
  };
  return axios.post('v1/reset-password/finish', body)
    .catch((error) => createError(error));
}
