<template>
  <base-tile class="forget-password-tile">
    <form
      @keyup.enter="resetPassword()"
    >
      <div class="px-2 pt-2">
        <h2>{{ $t('auth.resetPassword') }}</h2>
        <base-typology>{{ $t('auth.forgetPasswordSubtitle') }}</base-typology>
      </div>
      <div class="col px-0">
        <div class="row pt-3 mx-2">
          <aab-input
            :feedback-text="usernameValidationMessage"
            :feedback-state="usernameValidationMessage ? 'warning' : ''"
            icon-position="left"
          >
            <label
              slot="label"
              for="username"
            >
              {{ $t('user.launcherId') }}
            </label>
            <input
              id="username"
              v-model="username"
              name="username"
              type="text"
            >
            <aab-icon
              slot="icon"
              :svg="usernameIcon"
              size="3"
              :color="iconColor"
            />
          </aab-input>
        </div>
      </div>
    </form>
    <div
      v-if="error"
      id="error"
      class="notification pt-3 mx-2"
    >
      <aab-notification
        type="warning"
        :screenreader-type="$t('utils.warningAria')"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
    <div
      v-if="success"
      id="success-notification"
      class="notification pt-3 mx-2"
    >
      <aab-notification
        type="positive"
        :screenreader-type="$t('utils.positiveAria')"
      >
        <base-typology>{{ success }}</base-typology>
      </aab-notification>
    </div>
    <template #footer>
      <div class="py-3 px-2 mt-4 form-footer">
        <base-button
          id="reset-password"
          :text="$t('auth.resetPassword')"
          :disabled="loading"
          type="primary"
          @click="resetPassword()"
        >
        </base-button>
      </div>
    </template>
  </base-tile>
</template>

<script>
import { sy_profile_profile as syProfile } from '@aab/sc-aab-icon-set';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseTile from '@/components/base/BaseTile.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/utils/customValidation';
import { resetPasswordInit } from '@/services/userService';
import { n300 } from '@aab/sc-styles-colors';

export default {
  name: 'ForgetPassword',
  components: { BaseButton, BaseTile, BaseTypology },
  data() {
    return {
      v$: useVuelidate(),
      username: null,
      error: null,
      success: null,
      loading: false,
      iconColor: n300,
      usernameIcon: syProfile,
    };
  },
  computed: {
    usernameValidationMessage() {
      return this.v$.username.$error ? this.v$.$errors.filter((e) => e.$property === 'username')[0].$message : null;
    },
  },
  methods: {
    async resetPassword() {
      this.error = null;
      this.success = null;
      if (!await this.v$.$validate()) { return; }
      this.loading = true;
      try {
        await resetPasswordInit(this.username);
        this.success = this.$t('auth.resetPasswordSuccess');
        this.username = null;
        this.v$.$reset();
      } catch (e) {
        this.error = `${this.$t('auth.errors.forgetPassword')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
  },
  validations() {
    return {
      username: { required: helpers.withParams({ field: 'user.launcherId' }, required) },
    };
  },
};
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-3;
@include lib.mt-4;
@include lib.py-4;
@include lib.row;

.forget-password-tile {
  width: 450px;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  background-color: lib.$gm55;
}

.notification {
  max-width: inherit;
}

aab-input {
  width: 100%;
}

</style>
