<template>
  <div class="controls mb-2">
    <base-button
      id="back-button"
      class="mr-2"
      :text="$t('utils.toCompanyList')"
      type="secondary"
      :icon="backIcon"
      full-width
      @click="handleBack()"
    />
  </div>
  <div v-if="userStore.hasWriteRole">
    <account-form
      :company-id="company.id"
      @refresh-sec-accounts="getSecuritiesAccounts()"
    />
    <base-divider
      class="mt-3"
      :width="75"
    />
  </div>
  <div class="mt-3">
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <div v-else>
      <div class="spaceBetween mb-2">
        <div>
          <h3>{{ $t('maintenance.secAccounts') }}</h3>
        </div>
        <div>
          <base-button
            id="exportButton"
            text="Export"
            type="secondary"
            full-width
            :icon="downloadIcon"
            :disabled="exportBusy || !totalItems || totalItems === 0"
            @click="exportAccounts"
            @keyup.enter="exportAccounts"
          />
        </div>
      </div>
      <div
        v-if="downloadError"
        class="mb-2"
      >
        <aab-notification
          type="warning"
          :screenreader-type="$t('utils.warningAria')"
        >
          <p>{{ downloadError }}</p>
        </aab-notification>
      </div>
      <base-table
        :fields="fields"
        :items="secAccounts"
        :error="error"
        :empty-message="$t('maintenance.noSecAccounts', { company: company.name })"
        @icon-clicked="handleClick($event)"
      />
      <aab-pagination
        items-per-page="[25,50,75]"
        :selected-page-item="pageSize"
        :selected-page-number="pageNumber"
        :total-items="totalItems"
        @aab-pagination-updated="updateSecAccounts($event)"
      />
    </div>
  </div>
  <div v-if="showModal">
    <aab-modal
      modal-size="small"
      :is-open="showModal"
      @modal-close="cancelDelete()"
    >
      <div slot="modal-title">
        <base-typology tag="h3">
          {{ $t('utils.confirmDelete') }}
        </base-typology>
      </div>
      <div slot="modal-content">
        <base-typology>
          {{ $t('utils.deleteConfirmation', { item: deletionCheckMessage }) }}
        </base-typology>
      </div>
      <div
        slot="modal-footer"
        class="spaceBetween"
      >
        <base-button
          id="cancelDelete"
          type="secondary"
          :text="$t('utils.cancel')"
          @click="cancelDelete()"
        />
        <base-button
          id="confirmDelete"
          type="primary"
          :text="$t('utils.continue')"
          :icon="deleteIcon"
          @click="confirmDelete()"
        />
      </div>
    </aab-modal>
  </div>
</template>

<script>
import {
  sy_arrow_chevron_left as backIcon,
  sy_tools_trash as deleteIcon,
  sy_arrow_download as downloadIcon,
} from '@aab/sc-aab-icon-set';
import { defineComponent } from 'vue';
import {
  deleteSecAccounts,
  downloadSecAccounts,
  getCompany,
  getSecAccounts,
} from '@/services/maintenanceService';
import BaseTable from '@/components/base/BaseTable.vue';
import AccountForm from '@/components/maintenance/AccountForm.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDivider from '@/components/base/BaseDivider.vue';
import useUserStore from '@/stores/UserStore';
import { g300 } from '@aab/sc-styles-colors';
import { HttpError } from '@/utils/errors';
import BaseTypology from '@/components/base/BaseTypology.vue';

export default defineComponent({
  name: 'ManageAccounts',
  components: {
    BaseTypology,
    BaseDivider,
    BaseTable,
    BaseButton,
    AccountForm,
  },
  emits: ['change-title'],
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      loading: false,
      secAccounts: [],
      selectedSecAccount: null,
      showModal: false,
      error: null,
      pageNumber: 1,
      pageSize: 25,
      totalItems: null,
      company: {
        id: '',
        name: '',
      },
      exportBusy: false,
      downloadError: null,
      backIcon,
      deleteIcon,
      downloadIcon,
    };
  },
  computed: {
    fields() {
      let fields = [
        {
          key: 'secAccountHolder',
          label: 'Account Holder',
          sortable: true,
        },
        {
          key: 'secAccountNumber',
          label: 'Account Number',
          sortable: true,
        },
      ];
      if (this.userStore.hasWriteRole) {
        fields = [
          ...fields,
          {
            key: 'action',
            label: 'Actions',
            sortable: false,
            template: 'icon',
          },
        ];
      }
      return fields;
    },
    deletionCheckMessage() {
      return this.selectedSecAccount ? `securities account ${this.selectedSecAccount.secAccountNumber}?` : '';
    },
  },
  async created() {
    try {
      await this.getCompanyData();
      await this.getSecuritiesAccounts();
    } catch (e) {
      this.error = `${this.$t('maintenance.errors.getSecAccounts')} ${e.message}`;
    }
    this.$emit(
      'change-title',
      this.$t('maintenance.accountsTitle', { company: this.company.name }),
      this.$t('maintenance.secAccounts'),
    );
  },
  methods: {
    handleBack() {
      this.$router.push('/maintenance/companies');
    },
    async getCompanyData() {
      let data;
      if (this.$route.params.companyId) {
        data = await getCompany(this.$route.params.companyId);
        if (data) {
          this.company.id = data.companyId;
          this.company.name = data.companyName;
        } else {
          throw new HttpError(404, 'errors.404');
        }
      }
    },
    async getSecuritiesAccounts() {
      if (this.$route.params.companyId) {
        const data = await getSecAccounts(
          this.$route.params.companyId,
          this.pageSize,
          this.pageNumber,
        );
        if (data) {
          this.secAccounts = data.accounts
            .map((account) => ({
              ...account,
              action: [{
                size: '3',
                svg: deleteIcon,
                color: g300,
                action: 'delete',
                data: account.secAccountId,
                ariaLabel: 'delete securities account',
                role: 'button',
              }],
            }));
          this.totalItems = data.totalItems;
        }
      }
    },
    async updateSecAccounts(event) {
      this.pageSize = event.detail.value.itemsPerPage;
      this.pageNumber = event.detail.value.pageNumber;
      try {
        await this.getSecuritiesAccounts();
      } catch (e) {
        this.error = `${this.$t('maintenance.errors.getSecAccounts')} ${e.message}`;
      }
    },
    async exportAccounts() {
      this.exportBusy = true;
      this.downloadError = null;
      try {
        await downloadSecAccounts(this.company.id);
      } catch (e) {
        this.downloadError = `${this.$t('maintenance.errors.downloadSecAccounts')} ${e.message}`;
      } finally {
        this.exportBusy = false;
      }
    },
    handleClick(event) {
      if (event.detail.name === 'delete') {
        this.selectedSecAccount = this.secAccounts
          .find((account) => account.secAccountId === event.detail.data);
        this.showModal = true;
      }
    },
    async confirmDelete() {
      this.showModal = false;
      try {
        await deleteSecAccounts(this.company.id, this.selectedSecAccount.secAccountId);
        await this.getSecuritiesAccounts();
      } catch (e) {
        this.error = `${this.$t('maintenance.errors.deleteSecAccount')} ${e.message}`;
      } finally {
        this.selectedSecAccount = null;
      }
    },
    cancelDelete() {
      this.showModal = false;
      this.selectedSecAccount = null;
    },
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mt-3;
@include lib.row;
@include lib.col;
@include lib.container_fluid;

.controls {
  display: flex;
  justify-content: flex-start;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

</style>
