<template>
  <base-tile class="set-password-tile">
    <div>
      <div class="px-2 pt-2">
        <h2>{{ $t('user.welcome') }}</h2>
        <base-typology id="subtitle">
          {{ subtitle }}
        </base-typology>
      </div>
      <div
        v-if="error"
        class="mt-2 mx-2"
      >
        <aab-notification
          type="warning"
          :screenreader-type="$t('utils.warningAria')"
        >
          <p>{{ error }}</p>
        </aab-notification>
      </div>
      <password-form
        ref="childForm"
        @password-submit="checkValidation($event)"
      ></password-form>
    </div>
    <template #footer>
      <div class="footer">
        <base-button
          id="submit"
          :type="'primary'"
          :text="buttonLabel"
          :disabled="loading"
          @click="getPassword()"
        ></base-button>
      </div>
    </template>
  </base-tile>
</template>

<script>
import { defineComponent, ref } from 'vue';
import BaseTile from '@/components/base/BaseTile.vue';
import PasswordForm from '@/components/user/PasswordForm.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { activateAccount, resetPasswordFinish } from '@/services/userService';
import BaseButton from '@/components/base/BaseButton.vue';
import { useVuelidate } from '@vuelidate/core';

/*
 Component to set password when user is not logged in.
 This component requires a token to be able to do calls to the backend.
 It uses the generic password form that is also used when updating password when logged in.
*/
export default defineComponent({
  name: 'SetPassword',
  components: {
    BaseTypology,
    BaseButton,
    PasswordForm,
    BaseTile,
  },
  props: {
    action: {
      type: String,
      required: true,
      validator(val) {
        return ['activate', 'reset'].includes(val);
      },
    },
  },
  setup() {
    const childForm = ref(null);
    return { childForm };
  },
  data() {
    return {
      v$: useVuelidate(),
      error: null,
      loading: false,
    };
  },
  computed: {
    isResetPassword() {
      return (this.action === 'reset');
    },
    subtitle() {
      return this.isResetPassword ? this.$t('auth.resetPasswordSubtitle') : this.$t('auth.activateAccount.title');
    },
    buttonLabel() {
      return this.isResetPassword ? this.$t('auth.confirmPassword') : this.$t('auth.activateAccount.label');
    },
  },
  methods: {
    getPassword() {
      this.childForm.submit();
    },
    async checkValidation(event) {
      if (!await this.v$.$validate()) { return; }
      if (this.isResetPassword) {
        await this.resetPassword(event);
      } else {
        await this.activateAccount(event);
      }
    },
    async resetPassword(event) {
      this.loading = true;
      this.error = '';
      try {
        await resetPasswordFinish(event.data, this.$route.query.token);
        this.$router.push('/login');
      } catch (e) {
        this.error = `${this.$t('auth.errors.resetPassword')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
    async activateAccount(event) {
      this.loading = true;
      this.error = '';
      try {
        await activateAccount(event.data, this.$route.query.token);
        this.$router.push('/login');
      } catch (e) {
        this.error = `${this.$t('auth.errors.activateAccount')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-2;
@include lib.mt-2;
@include lib.row;

.footer {
  padding: 24px 16px 24px 16px;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  background-color: lib.$gm55;
}

.set-password-tile {
  width: 450px;
}
</style>
