import i18n from '@/i18n';

export const roles = {
  generalRead: {
    value: 'GENERAL_READ',
    key: 'roles.generalRead',
  },
  generalWrite: {
    value: 'GENERAL_WRITE',
    key: 'roles.generalWrite',
  },
  userManagement: {
    value: 'USER_MANAGEMENT',
    key: 'roles.userManagement',
  },
};

export const reportFrequencies = {
  weekly: {
    value: 'WEEKLY',
    key: 'reportFrequencies.weekly',
  },
  biweekly: {
    value: 'BIWEEKLY',
    key: 'reportFrequencies.biweekly',
  },
  monthly: {
    value: 'MONTHLY',
    key: 'reportFrequencies.monthly',
  },
};

export const lockedStatus = {
  false: 'Unlocked',
  true: 'Locked',
};

export const specialCharacters = '_!@#$%^&()*';

export const errorParams = {
  Company: i18n.global.t('errors.params.company'),
  Report: i18n.global.t('errors.params.report'),
  User: i18n.global.t('errors.params.user'),
  Token: i18n.global.t('errors.params.token'),
  SecuritiesAccount: i18n.global.t('errors.params.securitiesAccount'),
};
