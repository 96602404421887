<template>
  <div id="wrapper">
    <div
      v-if="userStore.hasWriteRole"
      class="controls mb-2"
    >
      <base-button
        id="add-company"
        class="ml-2"
        :text="$t('maintenance.addCompany')"
        :type="'primary'"
        :icon="addIcon"
        full-width
        @click="handleAddCompany()"
      />
    </div>
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <div v-else>
      <base-table
        :fields="fields"
        :items="companies"
        :error="error"
        :empty-message="$t('maintenance.noCompanies')"
        @icon-clicked="handleClick($event)"
      ></base-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseTable from '@/components/base/BaseTable.vue';
import {
  sy_shapes_plus_large as addIcon,
  sy_tools_pencil as pencilIcon,
  sy_account_accounts as accountsIcon,
  sy_form_document as reportIcon,
} from '@aab/sc-aab-icon-set';
import navItems from '@/utils/reference/navItems';
import { g300 } from '@aab/sc-styles-colors';
import useUserStore from '@/stores/UserStore';
import { getCompanies } from '@/services/maintenanceService';

export default defineComponent({
  name: 'CompanyList',
  components: { BaseButton, BaseTable },
  emits: ['change-title'],
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      addIcon,
      companies: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'companyName',
          label: this.$t('maintenance.companyName'),
          sortable: true,
        },
        {
          key: 'startDate',
          label: this.$t('maintenance.startDate'),
          sortable: true,
          sortKey: 'startDateNumber',
        },
        {
          key: 'endDate',
          label: this.$t('maintenance.endDate'),
          sortable: true,
          sortKey: 'endDateNumber',
        },
        {
          key: 'nextReportDate',
          label: this.$t('maintenance.nextReportDate'),
          sortable: true,
          sortKey: 'nextReportDateNumber',
        },
        {
          key: 'reportFrequency',
          label: this.$t('maintenance.reportFrequency'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('utils.actions'),
          sortable: false,
          template: 'icon',
        },
      ];
    },
  },
  created() {
    this.getDataCompanies();
    this.$emit('change-title', this.$t('maintenance.title'), this.$t('maintenance.label'));
  },
  methods: {
    async getDataCompanies() {
      this.loading = true;
      try {
        this.companies = await getCompanies();
        this.companies = this.addActions(this.companies);
      } catch (e) {
        this.error = `${this.$t('maintenance.errors.getCompanies')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
    handleAddCompany() {
      this.$router.push(navItems.maintenance.addCompany.route);
    },
    handleClick(event) {
      let routeName;
      switch (event.detail.name) {
        case 'update':
          routeName = navItems.maintenance.updateCompany.routeName;
          break;
        case 'accounts':
          routeName = navItems.maintenance.accounts.routeName;
          break;
        case 'reports':
          routeName = navItems.maintenance.reports.routeName;
          break;
        default:
          break;
      }
      if (routeName) {
        this.$router.push(
          {
            name: routeName,
            params: { companyId: event.detail.data },
          },
        );
      }
    },
    addActions(companies) {
      const that = this;
      return companies.map((company) => {
        const actions = that.getActions(company);
        return {
          ...company,
          actions,
        };
      });
    },
    getActions(company) {
      let actions = [{
        size: '3',
        svg: accountsIcon,
        color: g300,
        action: 'accounts',
        data: company.companyId,
        label: this.$t('maintenance.goTo.accounts'),
        role: 'button',
      },
      {
        size: '3',
        svg: reportIcon,
        color: g300,
        action: 'reports',
        data: company.companyId,
        label: this.$t('maintenance.goTo.reports'),
        role: 'button',
      }];
      if (this.userStore.hasWriteRole) {
        actions = [
          ...actions,
          {
            size: '3',
            svg: pencilIcon,
            color: g300,
            action: 'update',
            data: company.companyId,
            label: this.$t('maintenance.goTo.edit'),
            role: 'button',
          },
        ];
      }
      return actions;
    },
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mb-2;
@include lib.ml-2;

.controls {
  display: flex;
  justify-content: flex-end;
}

</style>
