import { defineStore } from 'pinia';
import { getRolesJwt } from '@/utils/jwtUtils';
import { roles } from '@/utils/reference/globalConstants';
import axios from 'axios';

/**
 * This store contains Pinia state and actions for authentication.
 */
const useUserStore = defineStore('userStore', {
  state: () => ({
    // holds the username of the current logged in user
    username: null,

    // holds the userConstants of the logged in user
    roles: [],

    // The returnUrl is used to redirect the user to the previous url after
    // successful login, it is set before an unauthenticated user is directed
    // to the login page.
    returnUrl: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.username,
    getRoles: (state) => state.roles,
    getUsername: (state) => state.username,
    getReturnUrl: (state) => state.returnUrl,
    hasWriteRole: (state) => state.roles.includes(roles.generalWrite.value),
    hasReadRole: (state) => state.roles.includes(roles.generalRead.value),
    hasUserRole: (state) => state.roles.includes(roles.userManagement.value),
  },
  actions: {
    /**
     * Post credentials to the API
     *
     * On success the returned user object is stored in Pinia and in localStorage
     */
    async auth(username, password) {
      const response = await axios.post('v1/login', { username, password });
      const userRoles = getRolesJwt(response.data.accessToken);

      localStorage.setItem('username', username);
      localStorage.setItem('roles', userRoles);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      this.setUser(username, userRoles);
    },

    /**
     * Sets the user to null in Pinia state, removes it from localStorage and
     * redirects to the login page.
     */
    logout() {
      this.setUser(null, []);

      localStorage.removeItem('username');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('roles');
    },

    setUser(username, userRoles) {
      this.username = username;
      this.roles = userRoles;
    },

    /**
     * Copy the username and userConstants from local storage to support staying logged in
     * between page refreshes and browser sessions.
     */
    autoLogin() {
      const localUsername = localStorage.getItem('username');
      const localRoles = localStorage.getItem('roles');
      if (localUsername && localRoles) {
        this.setUser(localUsername, localRoles);
      }
    },

    setReturnUrl(returnUrl) {
      this.returnUrl = returnUrl;
    },
  },
});

export default useUserStore;
