<template>
  <layout-plain
    :title-long="$t('title.long')"
    :title-short="$t('title.short')"
    :large-title-bar="true"
  >
    <div class="row">
      <div class="mx-auto">
        <login-form v-if="action === 'login'" />
        <set-password
          v-if="action === 'activate' || action === 'reset'"
          :action="action"
        />
        <forget-password v-if="action === 'forgotten'" />
      </div>
    </div>
  </layout-plain>
</template>

<script>
import { defineComponent } from 'vue';
import LayoutPlain from '@/layouts/LayoutPlain.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import SetPassword from '@/components/login/SetPassword.vue';
import ForgetPassword from '@/components/login/RequestResetPassword.vue';

/*
 This component is for views for users that are logged out
 and supply functionality to allow logging in. This includes:
 - Actually logging in
 - Activating the account by setting a password or setting password when user has forgotten it.
 - Resetting a forgotten password upon login attempt
*/
export default defineComponent({
  name: 'LoginView',
  components: {
    ForgetPassword,
    SetPassword,
    LoginForm,
    LayoutPlain,
  },
  props: {
    action: {
      type: String,
      required: true,
      validator(val) {
        return ['login', 'activate', 'forgotten', 'reset'].includes(val);
      },
    },
  },
});
</script>
