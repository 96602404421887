<template>
  <div>
    <aab-table
      :fields="fields"
      :items="items"
      :no-zebra="noZebra"
      :table-rows-not-clickable="tableRowsNotClickable"
      @icon-clicked="handleIconClick"
      @row-clicked="handleRowClick"
    >
      <div
        v-if="error"
        slot="empty"
        class="mb-2"
      >
        <aab-notification
          type="warning"
          :screenreader-type="$t('utils.warningAria')"
        >
          <base-typology>{{ error }}</base-typology>
        </aab-notification>
      </div>
      <div
        v-else
        slot="empty"
      >
        <base-typology>{{ emptyMessage }}</base-typology>
      </div>
    </aab-table>
  </div>
</template>

<script>
import BaseTypology from '@/components/base/BaseTypology.vue';
import { em_folder as emptyIcon } from '@aab/sc-aab-icon-set';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseTable',
  components: { BaseTypology },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
    noZebra: {
      type: Boolean,
      default: false,
    },
    tableRowsNotClickable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['icon-clicked', 'row-clicked'],
  data() {
    return {
      emptyIcon,
    };
  },
  methods: {
    handleIconClick(event) {
      this.$emit('icon-clicked', event);
    },
    handleRowClick(event) {
      this.$emit('row-clicked', event);
    },
  },
});
</script>
