import moment from 'moment-timezone';

const formatDate = (iso8601DateStr) => {
  const localLocale = moment.parseZone(iso8601DateStr);
  localLocale.locale('en');
  return localLocale.format('MMM D, YYYY');
};

/**
 * Create a formatted dateTime with fully written out timezone
 * adjusted for the user's local timezone.
 * I.e. when user is in CEST timezone, 2023-06-23T14:25:00+02:00
 * becomes Jun 23, 2023 - 14:25 Europe/Amsterdam.
 * When user is in GMT timezone, 2023-06-23T14:25:00+02:00
 * becomes Jun 23, 2023 - 12:25 Europe/London
 * @name formatDateTime
 * @param iso8601DateStr   the raw matching details data
 * @returns {String}       Matching details data, formatted
 */
const formatDateTime = (iso8601DateStr) => {
  const timezone = moment.tz.guess(true);
  const localLocale = moment(iso8601DateStr).tz(timezone);
  localLocale.locale('en');
  return localLocale.format('MMM D, YYYY - HH:mm z');
};

const formatDateTimeFromUnix = (unix) => moment.tz(new Date(unix * 1000), 'Europe/Amsterdam').format();

const formatDateFromUnix = (unix) => moment.tz(new Date(unix * 1000), 'Europe/Amsterdam').format('YYYY-MM-DD');

const formatUnixFromDateTime = (dateTime) => Math.round(new Date(dateTime).getTime() / 1000);

export {
  formatDate, formatDateTime, formatDateTimeFromUnix, formatDateFromUnix, formatUnixFromDateTime,
};
