import * as validators from '@vuelidate/validators';
import { specialCharacters } from '@/utils/reference/globalConstants';
import i18n from '@/i18n';

const { t } = i18n.global;

// create custom messageParams, so we can pass our own properties to i18n
const messageParams = (params) => ({
  ...params,
  field: params.field ? t(params.field) : '',
  sameAsField: params.sameAsField ? t(params.sameAsField) : '',
  characters: params.characters,
});

// create custom messagePath, so we can determine where in i18n our validation error messages are
const messagePath = ({ $validator }) => `validationErrors.${$validator}`;

// wrapping validations in our i18n instance so error messages are automated
const withI18nMessage = validators.createI18nMessage(
  { t: t.bind(i18n), messageParams, messagePath },
);

const required = withI18nMessage(validators.required);
const minLength = withI18nMessage(validators.minLength, { withArguments: true });
const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });

// create generic regular expressions to be used in validations
const atLeastOneLowerCaseCharRegExp = new RegExp(`^(?=.*[a-z])[A-Za-z\\d${specialCharacters}]+$`);
const atLeastOneUpperCaseCharRegExp = new RegExp(`^(?=.*[A-Z])[A-Za-z\\d${specialCharacters}]+$`);
const atLeastOneDigitRegExp = new RegExp(`^(?=.*\\d)[A-Za-z\\d${specialCharacters}]+$`);
const atLeastOneSpecialCharRegExp = new RegExp(`^(?=.*[${specialCharacters}])[A-Za-z\\d${specialCharacters}]+$`);

// create custom validators wrapped with our I18n instance
const atLeastOneLowerCaseChar = withI18nMessage(
  validators.helpers.regex(atLeastOneLowerCaseCharRegExp),
);
const atLeastOneUpperCaseChar = withI18nMessage(
  validators.helpers.regex(atLeastOneUpperCaseCharRegExp),
);
const atLeastOneDigit = withI18nMessage(
  validators.helpers.regex(atLeastOneDigitRegExp),
);
const atLeastOneSpecialChar = withI18nMessage(
  validators.helpers.regex(atLeastOneSpecialCharRegExp),
);

export {
  atLeastOneLowerCaseChar,
  atLeastOneUpperCaseChar,
  atLeastOneDigit,
  atLeastOneSpecialChar,
  required,
  minLength,
  sameAs,
};
