<template>
  <div>
    <div class="controls mb-2">
      <base-button
        id="back-button"
        class="mr-2"
        :text="$t('utils.toCompanyList')"
        :type="'secondary'"
        :icon="backIcon"
        full-width
        @click="handleBack()"
      />
    </div>
    <div
      v-if="error"
      id="errorMessage"
      class="mb-2"
    >
      <aab-notification
        type="warning"
        screenreader-type="Warning notification"
      >
        <p>{{ error }}</p>
      </aab-notification>
    </div>
    <div
      v-if="updateSuccess"
      id="successMessage"
      class="mb-2"
    >
      <aab-notification
        type="positive"
        :screenreader-type="$t('utils.positiveAria')"
        close-icon
        :close-icon-aria-label="$t('utils.closeAria')"
        @aab-notification-close-icon-clicked="handleClose()"
      >
        <p>{{ $t('maintenance.updateSuccess', { company: company.name }) }}</p>
      </aab-notification>
    </div>
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <form
      v-else
      class="form"
      @submit.prevent="handleAction"
    >
      <div id="inputCompanyName">
        <aab-input
          :feedback-text="companyNameValidationMessage"
          :feedback-state="companyNameValidationMessage ? 'warning' : null"
        >
          <label
            slot="label"
            for="companyName"
          >
            {{ $t('maintenance.companyName') }}
          </label>
          <input
            id="companyName"
            v-model="company.name"
            name="companyName"
            type="text"
          >
        </aab-input>
      </div>
      <div id="inputStartAndEndDate">
        <aab-datepicker
          class="mx-0"
          locale="en"
          with-range
          :left-label="$t('maintenance.startDate')"
          :right-label="$t('maintenance.endDate')"
          :start-date="initStartDate"
          :end-date="initEndDate"
          :message="startDateValidationMessage"
          :message-type="startDateValidationMessage ? 'warning' : null"
          :from-input-state="startDateValidationMessage ? 'warning' : null"
          @aab-datepicker-date-from-changed="handleChangeStartDate($event)"
          @aab-datepicker-date-to-changed="handleChangeEndDate($event)"
        />
      </div>
      <div id="inputNextReportDate">
        <aab-datepicker
          class="mx-0"
          locale="en"
          :left-label="$t('maintenance.nextReportDate')"
          :start-date="initNextReportDate"
          :min-selectable-date="minNextReportDate"
          @aab-datepicker-date-from-changed="handleChangeNextReportDate($event)"
        />
      </div>
      <div
        v-if="reportFrequencies"
        id="inputReportFrequency"
        class="mt-2"
      >
        <aab-select
          :aab-select-items="JSON.stringify(reportFrequencies)"
          :aab-select-label="$t('maintenance.reportFrequency')"
          :aab-select-value="company.reportFrequency"
          @aab-select-value-change="handleChangeFrequency($event)"
        />
      </div>
      <div class="mt-3 controls">
        <base-button
          id="action-button"
          class="mr-2"
          :text="actionButtonLabel"
          :type="'primary'"
          :icon="actionIcon"
          full-width
          :disabled="loading"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  sy_arrow_chevron_left as backIcon,
  sy_shapes_plus_large as addIcon,
  sy_form_valid as saveIcon,
} from '@aab/sc-aab-icon-set';
import BaseButton from '@/components/base/BaseButton.vue';
import { formatDateFromUnix, formatDateTimeFromUnix, formatUnixFromDateTime } from '@/utils/date';
import navItems from '@/utils/reference/navItems';
import { addCompany, getCompany, updateCompany } from '@/services/maintenanceService';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@/utils/customValidation';
import { helpers } from '@vuelidate/validators';
import { reportFrequencies } from '@/utils/reference/globalConstants';

export default defineComponent({
  name: 'CompanyForm',
  components: { BaseButton },
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  emits: ['change-title'],
  data() {
    return {
      v$: useVuelidate(),
      company: {
        id: '',
        name: '',
        startDate: null,
        endDate: null,
        nextReportDate: null,
        reportFrequency: reportFrequencies.monthly.value,
      },
      initStartDate: null,
      initEndDate: null,
      initNextReportDate: null,
      minNextReportDate: null,
      error: null,
      loading: false,
      updateSuccess: false,
      backIcon,
      addIcon,
    };
  },
  computed: {
    companyNameValidationMessage() {
      return this.v$.company.name.$error ? this.v$.$errors.filter((e) => e.$property === 'name')[0].$message : null;
    },
    startDateValidationMessage() {
      return this.v$.company.startDate.$error ? this.v$.$errors.filter((e) => e.$property === 'startDate')[0].$message : null;
    },
    actionButtonLabel() {
      return this.action === 'add' ? this.$t('maintenance.addCompany') : this.$t('maintenance.updateCompany');
    },
    actionIcon() {
      return this.action === 'add' ? addIcon : saveIcon;
    },
    reportFrequencies() {
      return Object.values(reportFrequencies).map((frequency) => ({
        value: frequency.value,
        name: this.$t(frequency.key),
      }));
    },
  },
  async created() {
    if (this.action === 'add') {
      this.$emit('change-title', this.$t('maintenance.addTitle'), null);
    } else {
      await this.getCompanyData();
      if (this.company.name) {
        this.$emit(
          'change-title',
          this.$t('maintenance.updateTitle', { company: this.company.name }),
          null,
        );
      }
    }
  },
  methods: {
    refreshCompany(data) {
      this.company.id = data.companyId;
      this.company.name = data.companyName;
      this.company.startDate = data.startDateTime;
      this.company.endDate = data.endDateTime;
      this.company.nextReportDate = data.nextReportDate;
      this.company.reportFrequency = data.reportFrequency;
      this.initStartDate = formatUnixFromDateTime(data.startDateTime);
      this.initEndDate = data.endDateTime
        ? formatUnixFromDateTime(data.endDateTime) : null;
      this.initNextReportDate = data.nextReportDate
        ? formatUnixFromDateTime(data.nextReportDate) : null;
      this.minNextReportDate = formatUnixFromDateTime(data.startDateTime);
    },
    async getCompanyData() {
      let data;
      if (this.$route.params.companyId) {
        try {
          data = await getCompany(this.$route.params.companyId);
          this.refreshCompany(data);
        } catch (e) {
          this.error = `${this.$t('maintenance.errors.getCompany')} ${e.message}`;
        }
      }
    },
    handleBack() {
      this.$router.push('/maintenance/companies');
    },
    async handleAction() {
      this.loading = true;
      this.error = null;
      if (!await this.v$.$validate()) {
        this.loading = false;
        return;
      }
      try {
        if (this.action === 'add') {
          const resp = await addCompany(this.company);
          this.$router.push(
            {
              name: navItems.maintenance.accounts.routeName,
              params: { companyId: resp.companyId },
            },
          );
        } else {
          const resp = await updateCompany(this.company);
          this.updateSuccess = true;
          this.refreshCompany(resp.data);
        }
      } catch (e) {
        if (this.action === 'add') {
          this.error = `${this.$t('maintenance.errors.addCompany')} ${e.message}`;
        } else {
          this.error = `${this.$t('maintenance.errors.updateCompany')} ${e.message}`;
        }
      } finally {
        this.loading = false;
      }
    },
    handleChangeStartDate(event) {
      this.minNextReportDate = event.detail.value;
      this.company.startDate = formatDateTimeFromUnix(event.detail.value);
      this.v$.company.startDate.$touch();
    },
    handleChangeEndDate(event) {
      this.company.endDate = formatDateTimeFromUnix(event.detail.value);
    },
    handleChangeNextReportDate(event) {
      this.company.nextReportDate = formatDateFromUnix(event.detail.value);
    },
    handleChangeFrequency(event) {
      this.company.reportFrequency = event.detail.value;
    },
    handleClose() {
      this.updateSuccess = false;
    },
  },
  validations() {
    return {
      company: {
        name: { required: helpers.withParams({ field: 'maintenance.companyName' }, required) },
        startDate: { required: helpers.withParams({ field: 'maintenance.startDate' }, required) },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mr-2;
@include lib.mt-2;
@include lib.mt-3;

.controls {
  display: flex;
  justify-content: flex-start;
}

.form {
  width: 500px;
}
</style>
