<template>
  <div class="mx-3">
    <h2 class="h2">
      {{ $t('account.changePassword.label') }}
    </h2>
    <div class="d-flex justify-content-start">
      <div id="new-password-form">
        <div
          v-if="error"
          id="error-notification"
          class="pt-3 mx-2"
        >
          <aab-notification
            type="warning"
            screenreader-type="Warning notification"
          >
            <p>{{ error }}</p>
          </aab-notification>
        </div>
        <div
          v-if="success"
          id="success-notification"
          class="pt-3 mx-2"
        >
          <aab-notification
            type="positive"
            :screenreader-type="$t('utils.positiveAria')"
          >
            <p>{{ success }}</p>
          </aab-notification>
        </div>
        <form class="pt-3 mx-2">
          <aab-inputblock-password
            class="form-element"
            icon-position="left"
          >
            <label
              slot="label"
              for="currentPassword"
            >
              {{ $t('user.currentPassword') }}
            </label>
            <input
              id="currentPassword"
              v-model="currentPassword"
              name="currentPassword"
              autocomplete="false"
            >
            <aab-icon
              slot="icon"
              :svg="icon"
              size="3"
              :color="iconColor"
            />
          </aab-inputblock-password>
        </form>
        <base-divider class="pt-3"></base-divider>
        <password-form
          ref="childForm"
          variant="update"
          @password-submit="checkValidation($event)"
        ></password-form>
        <div class="footer">
          <base-button
            id="submit"
            type="primary"
            :text="$t('account.changePassword.label')"
            :disabled="loading"
            @click="getNewPassword()"
          ></base-button>
        </div>
      </div>
      <div
        v-if="v$.$errors.length > 0"
        id="validation-notification"
        class="pt-3 mx-2"
      >
        <aab-notification
          type="warning"
          :screenreader-type="$t('utils.warningAria')"
        >
          <span>{{ $t('auth.errors.passwordRequirement') }}</span>
          <ul>
            <li
              v-for="validationError in v$.$errors"
              :key="validationError.$validator"
            >
              {{ validationError.$message }}
            </li>
          </ul>
        </aab-notification>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import PasswordForm from '@/components/user/PasswordForm.vue';
import { updatePassword } from '@/services/userService';
import { sy_tools_lock as syToolsLock } from '@aab/sc-aab-icon-set';
import { n300 } from '@aab/sc-styles-colors';
import BaseDivider from '@/components/base/BaseDivider.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/utils/customValidation';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'ChangePassword',
  components: { BaseDivider, PasswordForm, BaseButton },
  setup() {
    const childForm = ref(null);
    return { childForm };
  },
  data() {
    return {
      v$: useVuelidate(),
      icon: syToolsLock,
      iconColor: n300,
      error: null,
      success: null,
      loading: false,
      currentPassword: '',
    };
  },
  methods: {
    getNewPassword() {
      this.childForm.submit();
    },
    async checkValidation(event) {
      if (!await this.v$.$validate()) {
        return;
      }
      await this.updatePassword(event);
    },
    async updatePassword(event) {
      this.error = null;
      this.success = null;
      this.loading = true;
      try {
        await updatePassword(this.currentPassword, event.data);
        this.success = this.$t('account.changePassword.success');
        this.resetForm();
      } catch (e) {
        this.error = `${this.$t('account.changePassword.error')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.currentPassword = '';
      this.childForm.resetForm();
      this.v$.$reset();
    },
  },
  validations() {
    return {
      currentPassword: {
        required: helpers.withParams({ field: 'user.currentPassword' }, required),
        $autoDirty: true,
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.h2;
@include lib.mt_2;
@include lib.mx_2;
@include lib.mx_3;
@include lib.p_2;
@include lib.pt_3;
@include lib.d_flex;
@include lib.justify_content_start;

#new-password-form {
  width: 450px;
  flex-shrink: 0;
}

.form-element {
  width: 100%;
}

.footer {
  padding: 24px 16px 24px 16px;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

</style>
