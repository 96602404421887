<template>
  <layout-full-width
    :title-long="titleLong"
    :title-short="titleShort"
    large-title-bar
  >
    <div class="bg-white shadow-well py-4">
      <div class="mx-3">
        <router-view @change-title="setTitleKeys"></router-view>
      </div>
    </div>
  </layout-full-width>
</template>

<script>
import { defineComponent } from 'vue';
import LayoutFullWidth from '@/layouts/LayoutFullWidth.vue';

export default defineComponent({
  name: 'MaintenanceView',
  components: { LayoutFullWidth },
  data() {
    return {
      titleLongKey: null,
      titleShortKey: null,
    };
  },
  computed: {
    titleLong() {
      return this.titleLongKey ? this.titleLongKey : this.$t('maintenance.label');
    },
    titleShort() {
      return this.titleShortKey ? this.titleShortKey : this.$t('maintenance.label');
    },
  },
  methods: {
    setTitleKeys(longKey, shortKey) {
      this.titleLongKey = longKey;
      this.titleShortKey = shortKey || longKey;
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.shadow_well;
@include lib.py_4;
@include lib.mx_3;

</style>
