import { roles } from '@/utils/reference/globalConstants';

export default {
  maintenance: {
    routeName: 'Maintenance',
    i18nKey: 'maintenance.label',
    route: '/maintenance',
    requiresAuth: true,
    requiresRoles: [roles.generalRead.value, roles.generalWrite.value],
    addCompany: {
      routeName: 'AddCompany',
      i18nKey: 'maintenance.addCompany',
      route: 'addCompany',
    },
    companies: {
      routeName: 'Companies',
      i18nKey: 'maintenance.companies',
      route: 'companies',
    },
    updateCompany: {
      routeName: 'UpdateCompany',
      i18nKey: 'maintenance.updateCompany',
      route: 'update/:companyId',
    },
    accounts: {
      routeName: 'Accounts',
      i18nKey: 'maintenance.accounts',
      route: 'accounts/:companyId',
    },
    reports: {
      routeName: 'Reports',
      i18nKey: 'maintenance.reports',
      route: 'reports/:companyId',
    },
  },
  userManagement: {
    routeName: 'Users',
    i18nKey: 'userManagement.label',
    route: '/users',
    requiresAuth: true,
    requiresRoles: [roles.userManagement.value],
    users: {
      routeName: 'UserOverview',
      i18nKey: 'userManagement.userOverview',
      route: 'overview',
    },
    addUser: {
      routeName: 'AddUser',
      i18nKey: 'userManagement.addUser',
      route: 'addUser',
    },
  },
  account: {
    routeName: 'Account',
    i18nKey: 'account.label',
    route: '/account',
    requiresAuth: true,
    myAccount: {
      routeName: 'MyAccount',
      i18nKey: 'account.myAccount.label',
      route: 'myAccount',
    },
    changePassword: {
      routeName: 'ChangePassword',
      i18nKey: 'account.changePassword.label',
      route: 'changePassword',
    },
  },
};
