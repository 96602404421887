<template>
  <the-header class="position-relative"></the-header>
  <router-view />
</template>

<script>
import TheHeader from '@/components/header/TheHeader.vue';
import useUserStore from '@/stores/UserStore';
import { mapActions } from 'pinia';

export default {
  name: 'App',
  components: { TheHeader },
  created() {
    this.autoLogin();
  },
  methods: {
    ...mapActions(useUserStore, ['autoLogin']),
  },
};
</script>

<style lang="scss">
@use '@/styles/styles' as lib;

@include lib.position_relative;
</style>
