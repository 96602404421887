export default {
  "title": {
    "long": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Corporate Broking ", _linked("title.short", undefined, _type)])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance Tool"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten password?"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "forgetPasswordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your launcher ID to request a link to reset your password."])},
    "resetPasswordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set your new password."])},
    "resetPasswordSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset email successfully requested."])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in a valid corporate ID and password."])},
    "authenticationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passed credentials are invalid or the password is expired. Please enter valid credentials or reset your password."])},
    "activateAccount": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set your password to activate your account."])}
    },
    "errors": {
      "passwordRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please adhere to the following requirements:"])},
      "activateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error activating your account"])},
      "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error requesting the password reset email."])},
      "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error resetting your password."])}
    }
  },
  "maintenance": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company and securities account maintenance"])},
    "noCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no companies available"])},
    "noSecAccounts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no securities accounts linked to ", _interpolate(_named("company"))])},
    "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add company"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new company"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "secAccountHolderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder name"])},
    "secAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "goTo": {
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to transaction reports"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update company information"])},
      "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to securities accounts"])}
    },
    "nextReportDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next report date"])},
    "generateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate ad hoc report"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "reportFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting frequency"])},
    "secAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities accounts"])},
    "accountsTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Securities accounts of ", _interpolate(_named("company"))])},
    "addSecAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])},
    "addSecAccountTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a securities account"])},
    "updateTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update ", _interpolate(_named("company"))])},
    "updateCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update company"])},
    "updateSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update of ", _interpolate(_named("company")), " completed"])},
    "addSecAccountsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added securities account"])},
    "reportsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction reports"])},
    "reportsTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Transaction reports of ", _interpolate(_named("company"))])},
    "noReports": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no reports for ", _interpolate(_named("company"))])},
    "reportEmpty": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["The report you wish to download is empty. ", _linked("utils.contactInfo", undefined, _type)])},
    "errors": {
      "getCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the list of companies."])},
      "getCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error getting the company information."])},
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error adding the company."])},
      "addSecAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error adding the securities account."])},
      "updateCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating the company."])},
      "getSecAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the list of securities accounts."])},
      "deleteSecAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error deleting the securities account."])},
      "downloadSecAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error downloading the securities accounts overview."])},
      "getReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error retrieving the list of transaction reports."])},
      "downloadReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error in downloading the transaction report."])}
    }
  },
  "userManagement": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
    "userOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of users"])},
    "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no users for the compliance tool"])},
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a user"])},
    "errors": {
      "getUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error getting the users."])},
      "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error adding the user."])},
      "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error deleting the user."])}
    }
  },
  "account": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "myAccount": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])}
    },
    "changePassword": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating your password. Please check that your current password is correct."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully updated your password."])}
    },
    "errors": {
      "getUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred retrieving your details."])}
    }
  },
  "user": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
    "launcherId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launcher ID"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "role": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Role"]), _normalize(["Roles"])])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])}
  },
  "reportFrequencies": {
    "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    "biweekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biweekly"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])}
  },
  "roles": {
    "generalRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General read"])},
    "generalWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General write"])},
    "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])}
  },
  "utils": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "toCompanyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all companies"])},
    "toUserList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all users"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you wish to delete ", _interpolate(_named("item"))])},
    "closeAria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close icon"])},
    "warningAria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning notification"])},
    "positiveAria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive notification"])},
    "infoAria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information notification"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm delete"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions, please contact us at cbp.support", "@", "nl.abnamro.com."])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "errors": {
    "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no permission to access the requested resource."])},
    "resourceNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The requested ", _interpolate(_named("value")), " was not found."])},
    "duplicateConflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The request could not be completed because a ", _interpolate(_named("value1")), " with the same ", _interpolate(_named("value2")), " already exists."])},
    "processingError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The request could not be completed because there was an error processing the ", _interpolate(_named("value")), "."])},
    "resourceOwnershipError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This operation is not permitted because the specified ", _interpolate(_named("value1")), " does not belong to the specified ", _interpolate(_named("value2")), "."])},
    "fieldValidationError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The request could not be completed because the ", _interpolate(_named("value")), " field has failed validation."])},
    "passwordNoMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password verification failed because the provided password does not match."])},
    "passwordHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password history policy prevents reuse of recently used passwords."])},
    "passwordIdentical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new password should be different from the old password."])},
    "invalidRoleCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided combination of user roles is not valid."])},
    "notPermittedAccountStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested operation is not permitted in combination with your account status."])},
    "notPermittedSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot execute the requested operation on your own account."])},
    "accountLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested operation is not permitted because your account is locked."])},
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later, or contact us at cbp.support", "@", "nl.abnamro.com."])},
    "params": {
      "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resource"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
      "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
      "securitiesAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["securities account"])}
    }
  },
  "validationErrors": {
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' should contain at least ", _interpolate(_named("min")), " characters"])},
    "atLeastOneLowerCaseChar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' should contain at least one lower case character"])},
    "atLeastOneUpperCaseChar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' should contain at least one upper case character"])},
    "atLeastOneDigit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' should contain at least one digit (0-9)"])},
    "atLeastOneSpecialChar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' should contain one of the following characters: ", _interpolate(_named("characters"))])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' is required"])},
    "requiredPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fields")), " are required"])},
    "sameAs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("field")), "' should match '", _interpolate(_named("sameAsField")), "'"])}
  }
}