<template>
  <aab-button
    :button-style="type"
    :button-size="size"
    :full-width="fullWidth"
    :fluid-width="fluid"
    :icon-position="iconPosition"
  >
    <button :disabled="disabled">
      <aab-icon
        v-if="icon"
        :svg="icon"
        size="3"
        :color="iconColor"
        class="pr-1"
      ></aab-icon>
      {{ text }}
    </button>
  </aab-button>
</template>

<script>
import {
  g300,
  n400,
  n50,
  n670,
} from '@aab/sc-styles-colors';

export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'base',
      validator: (value) => ([
        'primary',
        'secondary',
        'base',
        'action',
      ].indexOf(value) !== -1),
    },
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ([
        'large',
        'medium',
      ].indexOf(value) !== -1),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ([
        'left',
        'right',
      ].indexOf(value) !== -1),
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconColor() {
      if (this.disabled) {
        return n400;
      }
      if (this.type === 'secondary') {
        return g300;
      }
      if (this.type === 'base') {
        return n50;
      }
      return n670;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.pr_1;
</style>
