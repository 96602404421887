<template>
  <div>
    <div class="controls mb-2">
      <base-button
        id="back-button"
        class="mr-2"
        :text="$t('utils.toUserList')"
        :type="'secondary'"
        :icon="backIcon"
        full-width
        @click="handleBack()"
      />
    </div>
    <div
      v-if="error"
      class="mb-2"
    >
      <aab-notification
        type="warning"
        :screenreader-type="$t('utils.warningAria')"
      >
        <p>{{ error }}</p>
      </aab-notification>
    </div>
    <div
      v-if="loading"
      id="spinner"
    >
      <aab-spinner />
    </div>
    <form
      v-else
      class="form"
      @submit.prevent="handleSubmit"
    >
      <div class="mt-2 form-name-container">
        <div class="form-name">
          <aab-input
            :feedback-text="firstNameValidationMessage"
            :feedback-state="firstNameValidationMessage ? 'warning' : ''"
          >
            <label
              slot="label"
              for="firstName"
            >
              {{ $t('user.firstName') }}
            </label>
            <input
              id="firstName"
              v-model="user.firstName"
              name="firstName"
              type="text"
            >
          </aab-input>
        </div>
        <div class="form-name">
          <aab-input
            :feedback-text="lastNameValidationMessage"
            :feedback-state="lastNameValidationMessage ? 'warning' : ''"
          >
            <label
              slot="label"
              for="lastName"
            >
              {{ $t('user.lastName') }}
            </label>
            <input
              id="lastName"
              v-model="user.lastName"
              name="lastName"
              type="text"
            >
          </aab-input>
        </div>
      </div>
      <div class="mt-2">
        <aab-input
          :feedback-text="usernameValidationMessage"
          :feedback-state="usernameValidationMessage ? 'warning' : ''"
        >
          <label
            slot="label"
            for="username"
          >
            {{ $t('user.launcherId') }}
          </label>
          <input
            id="username"
            v-model="user.username"
            name="username"
            type="text"
          >
        </aab-input>
      </div>
      <div class="mt-2">
        <aab-input
          :feedback-text="emailValidationMessage"
          :feedback-state="emailValidationMessage ? 'warning' : ''"
          helper-text="@nl.abnamro.com"
        >
          <label
            slot="label"
            for="email"
          >
            {{ $t('user.email') }}
          </label>
          <input
            id="email"
            v-model="user.email"
            name="email"
            type="email"
          >
        </aab-input>
      </div>
      <div
        v-if="roles"
        class="mt-2"
      >
        <aab-select
          :aab-select-items="JSON.stringify(roles)"
          :aab-select-label="$t('user.role')"
          :aab-select-value="user.role"
          @aab-select-value-change="handleChangeRole($event)"
        />
      </div>
      <div class="mt-2 controls">
        <base-button
          id="submit-button"
          class="mr-2"
          :text="$t('userManagement.addUser')"
          :type="'primary'"
          :icon="addIcon"
          full-width
          :disabled="loading"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  sy_arrow_chevron_left as backIcon,
  sy_shapes_plus_large as addIcon,
} from '@aab/sc-aab-icon-set';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { required } from '@/utils/customValidation';
import { roles } from '@/utils/reference/globalConstants';
import { addUser } from '@/services/userService';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'UserForm',
  components: { BaseButton },
  emits: ['change-title'],
  data() {
    return {
      v$: useVuelidate(),
      user: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        role: roles.generalRead.value,
      },
      error: null,
      loading: false,
      addIcon,
      backIcon,
    };
  },
  computed: {
    firstNameValidationMessage() {
      return this.v$.user.firstName.$error ? this.v$.$errors.filter((e) => e.$property === 'firstName')[0].$message : '';
    },
    lastNameValidationMessage() {
      return this.v$.user.lastName.$error ? this.v$.$errors.filter((e) => e.$property === 'lastName')[0].$message : '';
    },
    usernameValidationMessage() {
      return this.v$.user.username.$error ? this.v$.$errors.filter((e) => e.$property === 'username')[0].$message : '';
    },
    emailValidationMessage() {
      return this.v$.user.email.$error ? this.v$.$errors.filter((e) => e.$property === 'email')[0].$message : '';
    },
    roles() {
      return Object.values(roles).map((role) => ({
        value: role.value,
        name: this.$t(role.key),
      }));
    },
  },
  async created() {
    this.$emit('change-title', this.$t('userManagement.addUser'), null);
  },
  methods: {
    handleBack() {
      this.$router.push('/users/overview');
    },
    async handleSubmit() {
      this.loading = true;
      this.error = null;
      if (!await this.v$.$validate()) {
        this.loading = false;
        return;
      }
      try {
        await addUser(this.user);
        this.$router.push('/users/overview');
      } catch (e) {
        this.error = `${this.$t('userManagement.errors.addUser')} ${e.message}`;
      } finally {
        this.loading = false;
      }
    },
    handleChangeRole(event) {
      this.user.role = event.detail.value;
    },
  },
  // Role does not need to be validated as the UI does not allow the user to deselect a value
  validations() {
    return {
      user: {
        firstName: { required: helpers.withParams({ field: 'user.firstName' }, required) },
        lastName: { required: helpers.withParams({ field: 'user.lastName' }, required) },
        username: { required: helpers.withParams({ field: 'user.username' }, required) },
        email: { required: helpers.withParams({ field: 'user.email' }, required) },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mr-2;
@include lib.mt-2;
@include lib.row;
@include lib.d-flex;
@include lib.justify_content_between;
@include lib.col_6;
@include lib.col_sm-12;

.controls {
  display: flex;
  justify-content: flex-start;
}

.form-name-container {
  display: flex;
  justify-content: space-between;
  gap: 16px
}

.form-name {
  flex-grow: 1;
}

.form {
  width: 500px;
}
</style>
