import i18n from '@/i18n';
import { errorParams } from '@/utils/reference/globalConstants';

export function getErrorMessage(code, params) {
  switch (code) {
    case 'ACCESS_DENIED':
      return i18n.global.t('errors.accessDenied');
    case 'RESOURCE_NOT_FOUND':
      if (params) {
        return i18n.global.t('errors.resourceNotFound', { value: params[0] });
      }
      return i18n.global.t('errors.resourceNotFound', { value: i18n.global.t('errors.params.resource') });
    case 'DUPLICATE_CONFLICT':
      return i18n.global.t('errors.duplicateConflict', { value1: params[0], value2: params[1] });
    case 'JSON_PROCESSING_ERROR':
      return i18n.global.t('errors.processingError', { value: params[0] });
    case 'RESOURCE_OWNERSHIP_ERROR':
      return i18n.global.t('errors.resourceOwnershipError', { value1: params[0], value2: params[1] });
    case 'FIELD_VALIDATION_ERROR':
      return i18n.global.t('errors.fieldValidationError', { value: params[0] });
    case 'PASSWORD_DOES_NOT_MATCH':
      return i18n.global.t('errors.passwordNoMatch');
    case 'PASSWORD_HISTORY_POLICY_VIOLATION':
      return i18n.global.t('errors.passwordHistory');
    case 'PASSWORD_IDENTICAL':
      return i18n.global.t('errors.passwordIdentical');
    case 'INVALID_ROLE_COMBINATION':
      return i18n.global.t('errors.invalidRoleCombination');
    case 'NOT_PERMITTED_IN_ACCOUNT_STATUS':
      return i18n.global.t('errors.notPermittedAccountStatus');
    case 'NOT_PERMITTED_ON_SELF':
      return i18n.global.t('errors.notPermittedSelf');
    case 'ACCOUNT_LOCKED':
      return i18n.global.t('errors.accountLocked');
    default:
      console.warn('Unknown error code provided by backend');
      return i18n.global.t('errors.unspecified');
  }
}

export function getParams(rawParams) {
  const params = [];
  if (rawParams.length > 0) {
    rawParams.forEach((rawParam) => {
      if (errorParams[rawParam]) {
        params.push(errorParams[rawParam]);
      } else {
        params.push(i18n.global.t('errors.params.resource'));
        console.warn('Unknown error parameter provided by backend');
      }
    });
  }
  return params;
}

export function HttpError(code, status, message) {
  this.code = code.toString();
  this.status = status;
  this.message = message;
}

export default function createError(error) {
  const { code } = error.response.data.errors[0];
  const { status } = error.response.data.errors[0];
  // create param list to insert into translated error message
  const params = getParams(error.response.data.errors[0].params);
  throw new HttpError(code, status, getErrorMessage(code, params));
}
