import * as changeCase from 'change-case';
import { formatDate, formatDateTime, formatUnixFromDateTime } from '@/utils/date';
import createError from '@/utils/errors';
import axios from 'axios';

function createCompanyData(companies) {
  return Object.keys(companies)
    .map((key) => ({
      companyId: companies[key].companyId,
      companyName: companies[key].companyName,
      startDate: companies[key].startDateTime ? formatDate(companies[key].startDateTime) : '-',
      startDateNumber: companies[key].startDateTime
        ? formatUnixFromDateTime(companies[key].startDateTime) : 0,
      endDate: companies[key].endDateTime ? formatDate(companies[key].endDateTime) : '-',
      endDateNumber: companies[key].endDateTime
        ? formatUnixFromDateTime(companies[key].endDateTime) : 0,
      nextReportDate: companies[key].nextReportDate ? formatDate(companies[key].nextReportDate) : '-',
      nextReportDateNumber: companies[key].nextReportDate
        ? formatUnixFromDateTime(companies[key].nextReportDate) : 0,
      reportFrequency: changeCase.capitalCase(companies[key].reportFrequency),
    }));
}

function createSecAccountData(secAccounts) {
  const data = Object.keys(secAccounts.list)
    .map((key) => ({
      secAccountId: secAccounts.list[key].securitiesAccountId,
      secAccountNumber: secAccounts.list[key].securitiesAccountNumber,
      secAccountHolder: secAccounts.list[key].securitiesAccountHolderName,
    }));
  return {
    accounts: data,
    totalItems: secAccounts.totalElements,
  };
}

function createReportData(reports) {
  const data = Object.keys(reports.list)
    .map((key) => ({
      companyId: reports.list[key].companyId,
      startDate: reports.list[key].startDate ? formatDate(reports.list[key].startDate) : '-',
      startDateNumber: reports.list[key].startDate
        ? formatUnixFromDateTime(reports.list[key].startDate) : 0,
      endDate: reports.list[key].endDate ? formatDate(reports.list[key].endDate) : '-',
      endDateNumber: reports.list[key].endDate
        ? formatUnixFromDateTime(reports.list[key].endDate) : 0,
      reportId: reports.list[key].reportId,
      created: reports.list[key].creationDateTime ? formatDateTime(reports.list[key].creationDateTime) : '-',
      createdDateNumber: reports.list[key].creationDateTime
        ? formatUnixFromDateTime(reports.list[key].creationDateTime) : 0,
      createdBy: reports.list[key].createdBy,
    }));
  return {
    reports: data,
    totalItems: reports.totalElements,
  };
}

function downloadFile(resp) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([resp.data]));
  const fileName = resp.headers['content-disposition']
    .split('filename="')[1]
    .split('"')[0];
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Get the companies and their attributes from database
 * @name getCompanies
 * @returns {Promise}    List of companies
 */
export async function getCompanies() {
  return axios.get('v1/companies')
    .then((response) => createCompanyData(response.data.list))
    .catch((error) => createError(error));
}

/**
 * Add a company and its attributes to the database.
 * @name addCompany
 * @param company      object with company name, start date and end date
 * @returns {Promise}  Company data with CompanyId
 */
export async function addCompany(company) {
  const body = {
    companyName: company.name,
    startDateTime: company.startDate,
    endDateTime: company.endDate,
    nextReportDate: company.nextReportDate,
    reportFrequency: company.reportFrequency,
  };
  return axios.post('v1/companies', body)
    .then((response) => response.data)
    .catch((error) => createError(error));
}

/**
 * Update a company and its attributes.
 * @name updateCompany
 * @param company       object with company ID, name, start date and end date
 */
export async function updateCompany(company) {
  const body = {
    companyId: company.id,
    companyName: company.name,
    startDateTime: company.startDate,
    endDateTime: company.endDate,
    nextReportDate: company.nextReportDate,
    reportFrequency: company.reportFrequency,
  };
  return axios.put('v1/companies', body)
    .catch((error) => createError(error));
}

/**
 * Get a single company and its attributes by ID.
 * @name getCompany
 * @param companyId   company ID
 * @returns {Promise}  the company
 */
export async function getCompany(companyId) {
  return axios.get(`v1/companies/${companyId}`)
    .then((response) => response.data)
    .catch((error) => createError(error));
}

/**
 * Add a company and its attributes to the database.
 * @name addSecAccount
 * @param secAccount    Object with sec account holder name and number
 * @param companyId     The company ID to which to add the sec account
 * @returns {Object}    Company data with CompanyId
 */
export async function addSecAccount(secAccount, companyId) {
  const body = {
    companyId,
    securitiesAccountHolderName: secAccount.holderName,
    securitiesAccountNumber: secAccount.number,
  };
  return axios.post(`v1/companies/${companyId}/securities-accounts`, body)
    .then((response) => response.data)
    .catch((error) => createError(error));
}

/**
 * Get the linked securities accounts from company.
 * @name getSecAccounts
 * @param companyId     The company ID
 * @param pageSize      The page size
 * @param pageNumber    The page number
 * @returns {Promise}   Securities accounts
 */
export async function getSecAccounts(companyId, pageSize, pageNumber) {
  return axios.get(`v1/companies/${companyId}/securities-accounts?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    .then((response) => createSecAccountData(response.data))
    .catch((error) => createError(error));
}

/**
 * Delete a linked securities account from a company
 * @name deleteSecAccounts
 * @param companyId     The company ID
 * @param secAccountId  The securities account ID
 * @returns {Promise}   Affirmation
 */
export async function deleteSecAccounts(companyId, secAccountId) {
  return axios.delete(`v1/companies/${companyId}/securities-accounts/${secAccountId}`)
    .catch((error) => createError(error));
}

/**
 * Get the export of the securities accounts.
 * @name downloadSecAccounts
 * @param companyId     The company ID
 * @returns {Promise}   Securities accounts
 */
export async function downloadSecAccounts(companyId) {
  return axios.get(`v1/companies/${companyId}/securities-account-reports`, {
    responseType: 'blob',
  })
    .then((response) => downloadFile(response))
    .catch((error) => createError(error));
}

/**
 * Get the reports for company.
 * @name getReports
 * @param companyId     The company ID
 * @param pageSize      The page size
 * @param pageNumber    The page number
 * @returns {Promise}   Transaction reports
 */
export async function getReports(companyId, pageSize, pageNumber) {
  return axios.get(`v1/companies/${companyId}/transactions-reports?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    .then((response) => createReportData(response.data))
    .catch((error) => createError(error));
}

/**
 * Download the report for company. Returns status to handle empty files.
 * @name downloadReport
 * @param companyId    The company ID
 * @param reportId     The page size
 * @returns {Promise}  Status
 */
export async function downloadReport(companyId, reportId) {
  return axios.get(`v1/companies/${companyId}/transactions-reports/${reportId}`, {
    responseType: 'blob',
  })
    .then((resp) => {
      // catch empty reports
      if (resp.status === 204) {
        return resp.status;
      }
      downloadFile(resp);
      return 200;
    })
    .catch((error) => createError(error));
}

/**
 * Generate and download the ad hoc report for company. Returns status to handle empty files.
 * @name generateReport
 * @param companyId    The company ID
 * @param startDate    The start date of the report
 * @param endDate      The end date of the report
 * @returns {Promise}  Status
 */
export async function generateReport(companyId, startDate, endDate) {
  return axios.post('v1/transactions-reports', {
    companyId,
    startDate,
    endDate,
  }, {
    responseType: 'blob',
  })
    .then(async (resp) => {
      // catch empty reports
      if (resp.status === 204) {
        return resp.status;
      }
      downloadFile(resp);
      return 200;
    })
    .catch((error) => createError(error));
}
