<template>
  <div>
    <h3>{{ $t('maintenance.addSecAccountTitle') }}</h3>
    <div>
      <div
        v-if="loading"
        id="spinner"
      >
        <aab-spinner />
      </div>
      <form
        v-else
        @submit.prevent="addSecAccount()"
      >
        <div class="mt-2 form">
          <aab-input
            :feedback-text="holderNameValidationMessage"
            :feedback-state="holderNameValidationMessage ? 'warning' : ''"
          >
            <label
              slot="label"
              for="holderName"
            >
              {{ $t('maintenance.secAccountHolderName') }}
            </label>
            <input
              id="holderName"
              v-model="secAccount.holderName"
              name="holderName"
              type="text"
            >
          </aab-input>
        </div>
        <div class="mt-2 form">
          <aab-input
            :feedback-text="accountNumberValidationMessage"
            :feedback-state="accountNumberValidationMessage ? 'warning' : ''"
          >
            <label
              slot="label"
              for="accountNumber"
            >
              {{ $t('maintenance.secAccountNumber') }}
            </label>
            <input
              id="accountNumber"
              v-model="secAccount.number"
              name="accountNumber"
              type="text"
            >
          </aab-input>
        </div>
        <div class="mt-3 form notifications">
          <div
            v-if="error"
            id="error"
          >
            <aab-notification
              type="warning"
              :screenreader-type="$t('utils.warningAria')"
            >
              <p>{{ error }}</p>
            </aab-notification>
          </div>
          <div v-if="updateSuccess">
            <aab-notification
              type="positive"
              :screenreader-type="$t('utils.positiveAria')"
              close-icon
              @aab-notification-close-icon-clicked="handleClose()"
            >
              <p>{{ $t('maintenance.addSecAccountsSuccess') }}</p>
            </aab-notification>
          </div>
        </div>
        <div class="mt-3 controls">
          <base-button
            id="submit-button"
            :text="$t('maintenance.addSecAccountLabel')"
            type="primary"
            :icon="addIcon"
            full-width
            :disabled="loading"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  sy_shapes_plus_large as addIcon,
} from '@aab/sc-aab-icon-set';
import BaseButton from '@/components/base/BaseButton.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, maxLength } from '@vuelidate/validators';
import { required } from '@/utils/customValidation';
import { defineComponent } from 'vue';
import { addSecAccount } from '@/services/maintenanceService';

export default defineComponent({
  name: 'AccountForm',
  components: { BaseButton },
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  emits: ['refresh-sec-accounts'],
  data() {
    return {
      v$: useVuelidate(),
      secAccount: {
        holderName: '',
        number: '',
      },
      loading: false,
      error: null,
      updateSuccess: false,
      addIcon,
      maxLength: 250,
    };
  },
  computed: {
    holderNameValidationMessage() {
      return this.v$.secAccount.holderName.$error ? this.v$.$errors.filter((e) => e.$property === 'holderName')[0].$message : '';
    },
    accountNumberValidationMessage() {
      return this.v$.secAccount.number.$error ? this.v$.$errors.filter((e) => e.$property === 'number')[0].$message : '';
    },
  },
  methods: {
    async addSecAccount() {
      this.loading = true;
      this.error = null;
      if (!await this.v$.$validate()) {
        this.loading = false;
        return;
      }
      try {
        await addSecAccount(this.secAccount, this.companyId);
        this.updateSuccess = true;
        this.secAccount.holderName = '';
        this.secAccount.number = '';
        this.v$.secAccount.holderName.$dirty = false;
        this.v$.secAccount.number.$dirty = false;
        this.$emit('refresh-sec-accounts');
      } catch (e) {
        this.error = `${this.$t('maintenance.errors.addSecAccount')} ${e.message}`;
      }
      this.loading = false;
    },
    handleClose() {
      this.updateSuccess = false;
    },
  },
  validations() {
    return {
      secAccount: {
        holderName: {
          required: helpers.withParams({ field: 'maintenance.secAccountHolderName' }, required),
          maxLength: maxLength(this.maxLength),
        },
        number: {
          required: helpers.withParams({ field: 'maintenance.secAccountNumber' }, required),
          maxLength: maxLength(this.maxLength),
        },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '../../styles/styles.scss' as lib;

@include lib.mb-2;
@include lib.mr-2;
@include lib.mt-2;
@include lib.mt-3;

.form {
  width: 500px;
}

.controls {
  display: flex;
  justify-content: flex-start;
}

</style>
