<template>
  <div class="container-fluid max-w-layout px-md-3">
    <div class="row">
      <div
        id="titleBar"
        :class="['col-12 px-0 spx-md-2',titleBarHeight]"
      >
        <div
          v-if="backButtonPath"
          class="mb-1 sml-md--1 ml-1"
        >
          <aab-action
            :label="backButtonTitle"
            :icon="backIcon"
            :inverted="true"
            @click="onClick(backButtonPath)"
          >
          </aab-action>
        </div>
        <slot name="titleBar">
          <h2 class="h1 mb-0">
            <span
              id="titleBarLong"
              class="d-md-block d-none-max-md text-white"
            >{{ titleLong }}</span>
            <span
              id="titleBarShort"
              class="d-md-none d-block ml-2 mb-0 mt-1 text-white"
            >{{ titleShort }}</span>
          </h2>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { sy_arrow_chevron_leftlarge as syArrowChevronLeftLarge } from '@aab/sc-aab-icon-set';

export default {
  name: 'TitleBar',
  props: {
    titleLong: {
      type: String,
      required: true,
    },
    titleShort: {
      type: String,
      required: true,
    },
    backButtonPath: {
      type: String,
      default: null,
    },
    backButtonTitle: {
      type: String,
      default: null,
    },
    largeTitleBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backIcon: syArrowChevronLeftLarge,
    };
  },
  computed: {
    titleBarHeight() {
      return this.largeTitleBar ? 'py-5' : 'py-3';
    },
  },
  methods: {
    onClick() {
      this.$router.push(this.backButtonPath);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.col_12;
@include lib.container_fluid;
@include lib.d_block;
@include lib.d_md_block;
@include lib.d_md_none;
@include lib.d_none;
@include lib.text_white;

@include lib.h2;
@include lib.mb_0;
@include lib.mb_1;
@include lib.ml_1;
@include lib.mt_1;
@include lib.px_0;
@include lib.px_md_3;
@include lib.py_3;
@include lib.py_5;
@include lib.row;
@include lib.sml_md__1;
@include lib.spx_md_2;

@media (max-width: 768px){
  .d-none-max-md {
    display: none;
  }
}

</style>
