<template>
  <component
    :is="componentName"
    :class="[variant, textColor]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'BaseTypology',
  props: {
    variant: {
      type: String,
      default: 'paragraph',
      validator(val) {
        return ['paragraph', 'label', 'small', 'intro', 'link'].includes(val);
      },
    },
    color: {
      type: String,
      validator(val) {
        return ['dark', 'light', 'gray', 'lightGray', 'green'].includes(val);
      },
      default: null,
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  computed: {
    componentName() {
      if (this.variant === 'link') {
        return 'a';
      }
      return this.tag;
    },
    textColor() {
      if (this.color) {
        return this.color;
      }
      if (this.variant === 'paragraph') {
        return 'dark';
      }
      if (this.variant === 'link' || this.variant === 'label') {
        return 'green';
      }
      return 'dark';
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.p;
@include lib.label;
@include lib.text_small;
@include lib.text_intro;

@include lib.text_green;
@include lib.text_gray;
@include lib.text_light_gray;
@include lib.text_white;

.paragraph {
  // Cant be composed, because lib.p puts the styles on the p selector, rather than a class
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: lib.$n670;
}

.label {
  // Same as above
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #3c5e5e;
}

.small {
  composes: text-small;
}

.intro {
  composes: text-intro;
}

.link {
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s color;

  &:hover {
    color: lib.$g200 !important;
  }
}

.dark {
  color: lib.$n670;
}

.green {
  color: lib.$g400;
}

.gray {
  color: lib.$n300;
}

.lightGray {
  color: lib.$n100;
}

.light {
  color: lib.$n50;
}
</style>
